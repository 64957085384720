<template>
  <div>
    <v-row>
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t('attribute.teacherFee')" />
      </v-col>
      <v-spacer />
      <v-col cols="6" sm="4" md="2">
        <v-btn
          width="11rem"
          height="2.5rem"
          color="primary"
          dark
          :disabled="['TeamLeader'].includes(authRole)"
          @click="isShowModal = true"
          class="float-end"
        >
          <v-icon class="mr-3">mdi-cash</v-icon>
          {{ $_t("attribute.add") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="searching({ search: $event })"
          :headers="headers"
          :items="fees"
          :page-info="{ page, pageCount, itemsPerPage }"
          :loading="is_loading"
          hide-delete
        >
          <template v-slot:fullName="{ item }">
            {{ item.fullName }}
          </template>
          <template v-slot:action="{ item }">
            <v-btn color="primary" @click="openModal(item)">{{
              $_t("attribute.info")
            }}</v-btn>
          </template>
          <template v-slot:delete="{ item }">
            <v-btn
              color="red"
              :disabled="['TeamLeader'].includes(authRole)"
              outlined
              @click="openConfirmDelete(item)"
              >{{ $_t("attribute.delete") }}</v-btn
            >
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              @input="initFee({})"
            ></v-pagination>
          </template>
        </MainTable>
      </v-col>
    </v-row>
    <UpsertFee
      @update="initFee({})"
      @close="
        isShowModal = false;
        publicKey = null;
      "
      :is-show-modal="isShowModal"
      :public-key="publicKey"
      :readonly="['TeamLeader'].includes(authRole)"
    />
    <ConfirmDelete ref="confirmDelete" @confirm="deleteFee"> </ConfirmDelete>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import UpsertFee from "@/packages/finance/components/teacherFee/UpsertFee";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { ref } from "vue";
import { FEE_TABLE_SCHEMA } from "@/packages/finance/schema/FEE_TABLE_HEADER.schema";
import ConfirmDelete from "@/components/app/ConfirmDelete";
export default {
  name: "FeeTable",
  components: { ConfirmDelete, MainTable, UpsertFee, TopTitle },
  data() {
    return {
      isShowModal: false,
      fees: [],
      search: "",
      publicKey: null,
      teacherFeeId: null,
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      authRole: "authentication/role",
    }),
  },
  setup() {
    const headers = FEE_TABLE_SCHEMA.headers;
    const page = ref(1);
    const pageCount = ref(1);
    const itemsPerPage = ref(10);
    return {
      headers,
      page,
      pageCount,
      itemsPerPage,
    };
  },
  methods: {
    async initFee({ search = this.search }) {
      const { data, pageCount } = await this.$actions.getAllTeachersTeachingFee(
        {
          search: search,
          page: this.page,
          limit: this.itemsPerPage,
        }
      );
      this.fees = data;
      this.pageCount = pageCount;
    },
    async searching({ search = this.search }) {
      this.page = 1;
      await this.initFee({ search });
    },
    async deleteFee() {
      const res = await this.$actions.deleteTeacherFee({
        teacherFeeId: this.teacherFeeId,
      });
      this.teacherFeeId = null;
      if (res) await this.initFee({});
    },
    openModal(item) {
      this.publicKey = item.publicKey;
      this.isShowModal = true;
    },
    openConfirmDelete(item) {
      this.teacherFeeId = item.teacherFeeId;
      this.$refs.confirmDelete.open();
    },
  },
  mounted() {
    this.initFee({});
  },
};
</script>

<style scoped></style>
