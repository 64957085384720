import { _t } from "@/tools/Utils";

export const FEE_TABLE_SCHEMA = {
  headers: [
    {
      text: _t("admin.components.user_table.header.name"),
      align: "start",
      sortable: false,
      value: "fullName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.info"),
      sortable: false,
      value: "action",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.delete"),
      sortable: false,
      value: "delete",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
  ],
};
