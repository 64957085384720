<template>
  <FeeTable/>
</template>

<script>
import FeeTable from "@/packages/finance/components/teacherFee/FeeTable";
export default {
  name: "teacherFee",
  components: {FeeTable}
}
</script>

<style scoped>

</style>